<template>
  <el-dialog
    title="配置应用"
    :visible.sync="show"
    width="1000px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <div class="flex-end addBtn">
      <el-button type="primary" size="mini" @click="addFn"
        >新增空间应用</el-button
      >
    </div>
    <el-table
      :data="tableList"
      ref="table"
      :stripe="true"
      style="width: 100%;margin-top:10px"
      max-height="500px"
      border
      :header-cell-style="{ 'text-align': 'center ' }"
      :cell-style="{ 'text-align': 'center ' }"
    >
      <el-table-column label="应用名">
        <template slot-scope="{ row }">
          <el-select
            v-model="form.appId"
            placeholder="请选择应用"
            v-if="row.edit"
          >
            <el-option
              v-for="(item, idx) in applysList"
              :key="idx"
              :label="item.name"
              :value="item.appId.toString()"
              :disabled="item.checked"
            >
            </el-option>
          </el-select>
          <span v-else>{{ row.appName }}</span></template
        >
      </el-table-column>

      <el-table-column label="空间应用编码">
        <template slot-scope="{ row }">
          <el-input
            v-if="row.edit"
            type="textarea"
            v-model.trim="form.code"
            placeholder="请输入空间应用编码"
            clearable
            maxlength="255"
            show-word-limit
            class="inputWidth"
          />
          <span v-else>{{ row.code }}</span></template
        >
      </el-table-column>

      <el-table-column label="入口">
        <template slot-scope="{ row }">
          <el-input
            v-if="row.edit"
            type="textarea"
            v-model.trim="form.entry"
            placeholder="请输入空间入口"
            clearable
            maxlength="255"
            show-word-limit
            class="inputWidth w100"
          />
          <span v-else>{{ row.entry }}</span></template
        >
      </el-table-column>
      <el-table-column label="激活规则" prop="activateRule">
        <template slot-scope="{ row }">
          <el-input
            v-if="row.edit"
            type="textarea"
            v-model.trim="form.activateRule"
            placeholder="请输入激活规则"
            clearable
            maxlength="255"
            show-word-limit
            class="inputWidth"
          />
          <span v-else>{{ row.activateRule }}</span></template
        >
      </el-table-column>
      <el-table-column label="操作" width="100px"
        ><template slot-scope="{ row, $index }">
          <el-link
            class="link-box"
            type="primary"
            v-show="!row.edit"
            style="margin-right:10px"
            @click="optionFn(row, $index, 'edit')"
            >编辑</el-link
          >
          <el-popconfirm title="确定删除吗？" @confirm="deleteFn(row, $index)">
            <el-link
              class="link-box"
              type="primary"
              slot="reference"
              v-show="!row.edit"
              >删除</el-link
            >
          </el-popconfirm>
          <el-link
            class="link-box"
            type="primary"
            v-show="row.edit"
            style="margin-right:10px"
            @click="optionFn(row, $index, 'sure')"
            >确定</el-link
          >
          <el-link
            class="link-box"
            type="primary"
            v-show="row.edit"
            @click="optionFn(row, $index, 'cancel')"
            >取消</el-link
          >
        </template></el-table-column
      >
    </el-table>
    <!-- </div> -->
    <!-- </div> -->
  </el-dialog>
</template>

<script>
import { menus_apply } from '@/api/space_menus'
import {
  remove_config_apply,
  space_app_ifrepeat,
  save_config_apply,
} from '@/api/space'

export default {
  name: 'config_apply',
  props: {
    show: Boolean,
    spaceId: '',
  },
  data() {
    return {
      applysList: {}, //应用列表
      // selectApply: '',
      form: { appId: '', code: '', entry: '', activateRule: '' },
      // rules: {
      //   appId: [{ required: true, message: '请选择应用', trigger: 'change' }],
      //   code: [
      //     { required: true, message: '请输入空间应用编码', trigger: 'blur' },
      //   ],
      //   entry: [
      //     { required: true, message: '请输入空间应用入口', trigger: 'blur' },
      //   ],
      // },
      tableList: [],
      isEdit: false,
      currentEdit: {},
    }
  },
  // computed: {
  //   ...mapGetters(['applyList']),
  // },
  mounted() {},

  methods: {
    initData() {
      this.$nextTick(() => {
        // this.$refs.ruleForm.resetFields()
        this.isEdit = false
        this.form = { appId: '', code: '', entry: '', activateRule: '' }
      })
      this.getAllApplyList(() => {
        this.getConfigApplyList()
      })
    },
    addFn() {
      const hasEdit = this.tableList.some((cell) => {
        return cell.edit
      })
      if (hasEdit) {
        this.$message.warning('请完成编辑')
        return
      }
      // this.isEdit = true
      let a = { appId: '', code: '', entry: '', activateRule: '', edit: true }
      this.form = { appId: '', code: '', entry: '', activateRule: '' }
      this.tableList.unshift(a)
    },
    sureFn(row) {
      let f_ = { ...this.form }
      if (f_.appId == '') {
        this.$message.warning('请选择应用')
        return
      } else if (f_.code == '') {
        this.$message.warning('请输入空间应用名称')
        return
      } else if (f_.entry == '') {
        this.$message.warning('请输入空间应用入口')
        return
      }
      space_app_ifrepeat({
        id: !this.isEdit ? '' : row.spaceAppId.toString(),
        name: 'code',
        value: this.form.code,
      }).then((res) => {
        if (res) {
          this.$message.warning('空间应用编码已存在，请重新输入')
          return
        } else {
          let params = {}
          if (this.isEdit) {
            let obj = { ...row }
            delete obj.edit
            params = {
              ...row,
              ...this.form,
            }
          } else {
            params = {
              ...this.form,
              spaceId: this.spaceId,
            }
          }
          console.log(params, 'params')
          // return
          save_config_apply(params).then((res) => {
            this.$message.success(!this.isEdit ? '新增成功' : '保存成功')
            row.edit = false
            this.getConfigApplyList()
            this.isEdit = false
          })
        }
      })
    },

    closeFn() {
      this.$emit('close')
    },
    // 所有应用
    getAllApplyList(cb) {
      this.$store
        .dispatch('apply/fetchApplyList', { pageNumber: 1, pageSize: 100000 })
        .then((res) => {
          this.setApplysList(res)
          cb && cb()
        })
    },
    setApplysList(arr) {
      this.applysList = {}
      if (!arr.length) return
      arr.forEach((cell) => {
        this.applysList[cell.appId.toString()] = cell
        this.applysList[cell.appId.toString()].checked = false
      })
    },
    // 已经配置的应用
    async getConfigApplyList(cb) {
      const res = await menus_apply({
        spaceId: this.spaceId,
      })
      // console.log(this.applysList, ' this.applysList===')
      this.tableList =
        res.map((cell) => {
          this.applysList[cell.appId.toString()].checked = this.applysList[
            cell.appId.toString()
          ]
            ? true
            : false
          return {
            ...cell,
            appId: cell.appId.toString(),
            appName:
              this.applysList[cell.appId.toString()] &&
              this.applysList[cell.appId.toString()].name,
            edit: false,
          }
        }) || []
      console.log(this.tableList)
      cb && cb()
    },
    //操作
    optionFn(row, idx, type) {
      if (type == 'edit') {
        const hasEdit = this.tableList.some((cell) => {
          return cell.edit
        })
        if (hasEdit) {
          this.$message.warning('请完成编辑')
          return
        }
        row.edit = true
        this.form.code = row.code
        this.form.appId = row.appId.toString()
        this.form.entry = row.entry
        this.form.activateRule = row.activateRule
        this.isEdit = true
      } else if (type == 'sure') {
        this.sureFn(row)
      } else if (type == 'cancel') {
        if (row.hasOwnProperty('spaceAppId')) {
          //编辑
          row.edit = false
        } else {
          //新增
          this.tableList.shift()
          this.form = { appId: '', code: '', entry: '', activateRule: '' }
        }
      }
    },
    //删除应用
    deleteFn(row) {
      const spaceAppId = row.spaceAppId.toString()
      remove_config_apply({ spaceAppId: spaceAppId }).then(() => {
        this.getConfigApplyList(() => {
          this.$message.success('删除成功')
          this.applysList[row.appId.toString()].checked = false
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.inputWidth {
  width: 300px;
}
.line {
  width: 1px;
  margin-left: 10px;
  background: #f8f8f8;
  min-height: 280px;
}
.inputWidth {
  width: 200px;
}
</style>
